<template>
  <div class="mediaobject-list">
    <Toolbar :handle-add="addHandler" />

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h1>Media Object List</h1>
        </v-flex>
        <v-flex lg12>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <MediaObjectFilterForm
              ref="filterForm"
              :values="filters"
              slot="filter"
            />
          </DataFilter>

          <br />

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            show-select
            @update:options="onUpdateOptions"
          >
            <template v-slot:item.contentUrl="{ item }">
              <v-img v-if="isImageExtension(item.contentUrl)" contain max-height="75px" max-width="25vw" :src="getFileUrl(item.contentUrl)"/>
              <template v-else>{{ item.contentUrl.toUpperCase() }}</template>
            </template>

            <ActionCell
              slot="item.action"
              slot-scope="props"
              :handle-show="() => showHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import ActionCell from '../../components/ActionCell';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import MediaObjectFilterForm from "../../components/mediaobject/Filter";
import {isImageExtension} from '../../utils/filesType';
import {ENTRYPOINT} from "../../config/entrypoint";

export default {
  name: 'MediaObjectList',
  servicePrefix: 'MediaObject',
  mixins: [ListMixin],
  components: {
    MediaObjectFilterForm,
    Toolbar,
    ActionCell,
    DataFilter
  },
  data() {
    return {
      headers: [
        { text: 'url', value: 'contentUrl' },
        {
          text: 'Actions',
          value: 'action',
          sortable: false
        }
      ],
      selected: []
    };
  },
  computed: {
    ...mapGetters('mediaobject', {
      items: 'list'
    }),
    ...mapFields('mediaobject', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    ...mapActions('mediaobject', {
      getPage: 'fetchAll',
      deleteItem: 'del'
    }),
    isImageExtension,
    getFileUrl(path){
      return ENTRYPOINT+path
    }
  }
};
</script>
